<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Contacts</h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                @click="goCreateContact"
            >
                <b-icon-card-heading /> Créer un contact
            </b-button>
        </div>

        <div class="k-page-body">
            <TableContacts />
        </div>
    </div>
</template>
<script>
import TableContacts from "@/components/model/contact/TableContacts";

export default {
    components: {
        TableContacts,
    },

    methods: {
        goCreateContact: function() {
            this.$router.push({ name: "AdminCreateContact" });
        },
    },
};
</script>
